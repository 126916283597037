var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutVisiteur',[_c('section',{staticClass:"container-fluid bg-light"},[_c('div',{staticClass:"row p-2 p-lg-5"},[_c('div',{staticClass:"col-12 col-lg-4 d-none d-lg-block"}),_c('div',{staticClass:"col-12 col-lg-4 bg-secondary shadow text-light rounded p-4"},[_c('form',{staticClass:"row g-3",attrs:{"formnovalidate":"formnovalidate"},on:{"submit":function($event){$event.preventDefault();return _vm.confirm.apply(null, arguments)}}},[_c('h2',{staticClass:"text-primary"},[_vm._v("Retrouvez votre compte")]),_c('hr'),_c('div',[_vm._v("Veuillez entrer votre nouveau mot de passe.")]),_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputMail1"}},[_vm._v("Nouveau mot de passe")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pwd_visit),expression:"form.pwd_visit"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.pwd_visit.$error,
              },attrs:{"type":"password"},domProps:{"value":(_vm.form.pwd_visit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "pwd_visit", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.pwd_visit.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.pwd_visit.required)?_c('span',[_vm._v("Veuillez insérer votre nouveau mot de passe")]):_vm._e(),(_vm.form.pwd_visit && !_vm.$v.form.pwd_visit.valid)?_c('span',[_vm._v("Le mot de passe doit contenir au moins une majuscule, une minuscule, un nombre et un caractère spécial")]):_vm._e(),(
                  _vm.form.pwd_visit &&
                  _vm.$v.form.pwd_visit.valid &&
                  !_vm.$v.form.pwd_visit.minLength
                )?_c('span',[_vm._v("Le mot de passe doit contenir au moins 6 caractères")]):_vm._e(),(_vm.form.pwd_visit && !_vm.$v.form.pwd_visit.maxLength)?_c('span',[_vm._v("Le mot de passe ne doit pas dépasser les 15 caractères")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputMail1"}},[_vm._v("Confirmer le nouveau mot de passe")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_pwd),expression:"form.confirm_pwd"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.form.confirm_pwd.$error,
              },attrs:{"type":"password"},domProps:{"value":(_vm.form.confirm_pwd)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirm_pwd", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.confirm_pwd.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.confirm_pwd.required)?_c('span',[_vm._v("Veuillez confirmer votre nouveau mot de passe")]):(!_vm.$v.form.confirm_pwd.sameAsPassword)?_c('span',[_vm._v("Les deux mots de passent ne sont pas égaux")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-12 mb-2 mt-4 text-end"},[(_vm.loading)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-grow spinner-grow-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Envoi... ")]):_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","formnovalidate":"formnovalidate"}},[_vm._v(" Envoyer ")])])])]),_c('div',{staticClass:"col-12 col-lg-4 d-none d-lg-block"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }