<template>
  <LayoutVisiteur>
    <section class="container-fluid bg-light">
      <div class="row p-2 p-lg-5">
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
        <div class="col-12 col-lg-4 bg-secondary shadow text-light rounded p-4">
          <form
            class="row g-3"
            @submit.prevent="confirm"
            formnovalidate="formnovalidate"
          >
            <h2 class="text-primary">Retrouvez votre compte</h2>
            <hr />
            <div>Veuillez entrer votre nouveau mot de passe.</div>
            <div class="col-12">
              <label for="inputMail1" class="form-label"
                >Nouveau mot de passe</label
              >
              <input
                type="password"
                class="form-control"
                v-model="form.pwd_visit"
                :class="{
                  'is-invalid': submitted && $v.form.pwd_visit.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.pwd_visit.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.pwd_visit.required"
                  >Veuillez insérer votre nouveau mot de passe</span
                >
                <span v-if="form.pwd_visit && !$v.form.pwd_visit.valid"
                  >Le mot de passe doit contenir au moins une majuscule, une
                  minuscule, un nombre et un caractère spécial</span
                >
                <span
                  v-if="
                    form.pwd_visit &&
                    $v.form.pwd_visit.valid &&
                    !$v.form.pwd_visit.minLength
                  "
                  >Le mot de passe doit contenir au moins 6 caractères</span
                >
                <span v-if="form.pwd_visit && !$v.form.pwd_visit.maxLength"
                  >Le mot de passe ne doit pas dépasser les 15 caractères</span
                >
              </div>
            </div>
            <div class="col-12">
              <label for="inputMail1" class="form-label"
                >Confirmer le nouveau mot de passe</label
              >
              <input
                type="password"
                class="form-control"
                v-model="form.confirm_pwd"
                :class="{
                  'is-invalid': submitted && $v.form.confirm_pwd.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.confirm_pwd.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.confirm_pwd.required"
                  >Veuillez confirmer votre nouveau mot de passe</span
                >
                <span v-else-if="!$v.form.confirm_pwd.sameAsPassword"
                  >Les deux mots de passent ne sont pas égaux</span
                >
              </div>
            </div>
            <div class="col-12 mb-2 mt-4 text-end">
              <button
                v-if="loading"
                class="btn btn-primary"
                type="button"
                disabled
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Envoi...
              </button>
              <button
                v-else
                type="submit"
                class="btn btn-primary"
                formnovalidate="formnovalidate"
              >
                Envoyer
                <!-- <IcoSend /> -->
              </button>
            </div>
          </form>
        </div>
        <div class="col-12 col-lg-4 d-none d-lg-block"></div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import { success, error } from "../../utils/toast";
import { resetPassword } from "../../api/visiteur";
import { decodeToken } from "../../utils/decodeToken";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  name: "MdpForget",
  components: {
    LayoutVisiteur,
  },
  data() {
    return {
      form: {
        pwd_visit: "",
        confirm_pwd: "",
        id: 0,
      },
      loading: false,
      submitted: false,
    };
  },
  validations: {
    form: {
      pwd_visit: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-_|~]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
        minLength: minLength(6),
        maxLength: maxLength(15),
      },
      confirm_pwd: { required, sameAsPassword: sameAs("pwd_visit") },
    },
  },
  mounted() {
    try {
      const decodeV = decodeToken(this.$route.params.token);
      if (decodeV) {
        //console.log(decodeV);
        this.form.id = decodeV.id_visiteur;
      }
    } catch (err) {
      this.$router.push(this.$route.query.redirect || "/");
    }
  },
  methods: {
    confirm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      resetPassword(this.form)
        .then((result) => {
          if (result.data.error) {
            //console.log(result.data.error);
            error(result.data.error);
            this.loading = false;
          } else {
            this.loading = false;
            success("Votre mot de passe a été reinitialisé");
            this.$router.push(this.$route.query.redirect || "/se-connecter");
            //console.log(result);
          }
        })
        .catch((/*err*/) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
